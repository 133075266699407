figure > img {
    display: block;
}

// figcaption {
//     font-size: $small-font-size;
// }


// .blankbox {
//     background: $theme-color;
// }
// .img_row {
//     height: $img-height;
//     max-width: 100%;
//     overflow: hidden;
//     box-sizing:border-box;
// }
// .col {
//     width: 100%;
//     height: 100%;
//     float: left;
//     object-fit: cover;
//     box-sizing:border-box;
//     padding: $img-spacing;
// }
.one {
    width:33.33%;
}
.two {
    width: 66.66%;
}
.three {
    width: 100%;
}
.thumbnail {
    width: 50%;
}
.nthumbnail {
    max-width: 100px;
}
.nathumbnail {
    max-width: 200px;
}
// .caption {
//     height: 100%;
//     color: $caption-color;
//     text-align: center;
//     vertical-align: middle;
//     font-size: $caption-font-size;
// }

.center-image
{
    margin: 0 auto;
    display: block;
    width: 100%;
    height: auto;
}

.center-image-half
{
    margin: 0 auto;
    display: block;
    width: 50%;
    height: auto;
}

.center-image-three-fourth
{
    margin: 0 auto;
    display: block;
    width: 75%;
    height: auto;
}
